import React, { use, useEffect, useState } from "react";
import {
  Button,
  CssBaseline,
  TextField,
  Link,
  Paper,
  Box,
  Grid,
  Typography,
  createTheme,
  ThemeProvider,
  Fab,
  styled,
  AppBar,
  Toolbar,
  IconButton,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { useCookies } from "react-cookie";
import FontSizeChanger from "react-font-size-changer";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import packageJson from "../../../../../../package.json";
import BarTop from "../../BarTop";
import BarBottom from "../../BarBottom";
import FavoriteTwoToneIcon from "@mui/icons-material/FavoriteTwoTone";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { Helmet, HelmetProvider } from "react-helmet-async";

const serverId = process.env.REACT_APP_SERVER_ID;
const siteId = process.env.REACT_APP_SITE_ID;
const storeId = process.env.REACT_APP_STORE_ID;
const loginCustomerNumber = process.env.REACT_APP_LOGIN_CUSTOMER_NUMBER;
const siteMedSrc = process.env.REACT_APP_MEDIA_SRC;
let enableNewUI = process.env.REACT_APP_NEWUI_LW;
const restaurantName = process.env.REACT_APP_RESTAURANT_NAME;
const logoWidth = process.env.REACT_APP_LOGO_WIDTH;
const backgroundColor = process.env.REACT_APP_BACKGROUND_COLOR;
const buttonColor = process.env.REACT_APP_BUTTON_COLOR;
const hoverColor = process.env.REACT_APP_TEXT_HIGHLIGHT;
const buttonText = process.env.REACT_APP_BUTTON_TEXT;
const enableKiosk = process.env.REACT_APP_ENABLE_EKIOSK;
const tagline = process.env.REACT_APP_RESTAURANT_TAGLINE;

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      <br />
      Made with <FavoriteTwoToneIcon sx={{ color: "#820813" }} /> by
      <br />
      <Link color="inherit" target="_blank" href="https://servingintel.com/">
        <img
          className="silogo"
          src={`${siteMedSrc}si-logo.svg`}
          alt="ServingIntel"
        />
      </Link>{" "}
      {packageJson.version}
    </Typography>
  );
}

const StyledFab = styled(Fab)({
  position: "absolute",
  zIndex: 1,
  top: 0,
  left: 0,
  right: 0,
  margin: "0 auto",
  width: "360px",
  backgroundColor: "transparent !important",
  boxShadow: "none !important",
  textTransform: "none",
});

const theme = createTheme();

export const SignInSL = () => {
  const history = useHistory();
  const [newFontSize, setFontSize] = useState(16);
  const increaseFont = () => setFontSize(newFontSize + 2);

  const [cookie, setCookie, removeCookie] = useCookies();

  const [firstName, setFirstName] = useState(cookie.firstName || "");
  const [roomNumber, setRoomNumber] = useState(cookie.roomNumber || "");

  useEffect(() => {
    if (cookie.firstName !== undefined && cookie.roomNumber !== undefined) {
      validateAccount();
    }
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const firstNameURL = params.get("firstName");
    const roomNumberURL = params.get("roomNumber");
    console.log("firstNameURL: ", firstNameURL);
    console.log("roomNumberURL: ", roomNumberURL);
    if (firstNameURL && roomNumberURL) {
      sessionStorage.setItem("isAdminLogin", 1);
      setFirstName(firstNameURL);
      setRoomNumber(roomNumberURL);
    }
  }, []);

  useEffect(() => {
    if (parseInt(sessionStorage.getItem("isAdminLogin")) === 1) {
      validateAccount();
    }
  }, [firstName, roomNumber]);

  const validateAccount = () => {
    if (firstName === "" || roomNumber === "") {
      if (parseInt(sessionStorage.getItem("isAdminLogin")) !== 1) {
        alertify.alert(
          "Error",
          "Invalid Account! Please input your account details",
          function () {}
        );
      }
      return;
    }
    console.log("firstName: ", firstName);
    console.log("roomNumber: ", roomNumber);
    const url =
      loginCustomerNumber == 1
        ? `https://ecommv2.servingintel.com/customer/login-customer-number/${serverId}/${siteId}/${firstName}/${roomNumber}`
        : `https://ecommv2.servingintel.com/customer/login/${serverId}/${siteId}/${storeId}/${firstName}/${roomNumber}`;
    axios
      .get(url)
      .then((response) => {
        const message =
          parseInt(process.env.REACT_APP_LOGIN_ROOM_NUMBER) === 1 ||
          parseInt(sessionStorage.getItem("isAdminLogin")) === 1
            ? "The authentication has failed."
            : "Invalid Account! Please check your account details";

        if (!response.data || response.data.length === 0) {
          alertify.alert("Error", message, () => {});
        }

        const customer = response.data;

        if (
          customer.FirstName.toLowerCase() === firstName.toLowerCase() &&
          (customer.Address1.toLowerCase() === roomNumber.toLowerCase() ||
            customer.CustomerNumber == roomNumber)
        ) {
          if (
            parseInt(process.env.REACT_APP_LOGIN_ROOM_NUMBER) === 1 ||
            parseInt(sessionStorage.getItem("isAdminLogin")) === 1
          ) {
            sessionStorage.setItem("pinCodeOk", 1);
          }
          sessionStorage.setItem("customerDiscount", customer.Discount);
          sessionStorage.setItem("firstName", customer.FirstName);
          sessionStorage.setItem("lastName", customer.LastName);
          sessionStorage.setItem(
            "roomNumber",
            loginCustomerNumber == 1
              ? customer.CustomerNumber
              : customer.Address1
          );
          setCookie("firstName", customer.FirstName, { path: "/" });
          setCookie("roomNumber", customer.Address1, { path: "/" });
          setCookie("customerId", customer.CustomerID, { path: "/" });
          setCookie("customerDetails", customer, { path: "/" });
          const people = [
            {
              firstname: customer.FirstName,
              room: customer.Address1,
              custid: customer.CustomerID,
              lastname: customer.LastName,
            },
          ];
          if (cookie.customerList !== undefined) {
            const addPeople = [...cookie.customerList, ...people];
            const output = [
              ...new Map(addPeople.map((o) => [o.custid, o])).values(),
            ];
            setCookie("customerList", output, { path: "/" });
          } else {
            setCookie("customerList", people, { path: "/" });
          }

          if (
            customer.SIC_WebPin ||
            sessionStorage.getItem("isAdminLogin") == 1
          ) {
            history.push("/logincode");
          } else {
            history.push("/UpdateCode");
          }
        }
      })
      .catch((error) => {});
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    validateAccount();
  };

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            {restaurantName}: {tagline}
          </title>
        </Helmet>
      </HelmetProvider>
      <ThemeProvider theme={theme}>
        <Grid
          container
          component="main"
          justifyContent={enableKiosk === "1" ? "center" : null}
          alignItems={enableKiosk === "1" ? "center" : null}
          sx={{
            fontSize: enableKiosk === "0" ? `${newFontSize}px` : null,
            backgroundImage:
              enableKiosk === "1" ? `url(${siteMedSrc}signbg.png)` : null,
            backgroundRepeat: enableKiosk === "1" ? "no-repeat" : "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: enableKiosk === "1" ? "cover" : "cover",
            backgroundPosition: enableKiosk === "1" ? "left" : "left",
            left: enableKiosk === "1" ? "0" : "0",
            right: enableKiosk === "1" ? "0" : "0",
            minHeight: "100vh",
            height: enableKiosk === "0" ? "100vh" : null,
          }}
        >
          <CssBaseline />
          <Grid
            item
            xs={false}
            sm={enableKiosk === "1" ? false : 4}
            md={enableKiosk === "1" ? false : 7}
            sx={{
              backgroundRepeat: enableKiosk === "1" ? "no-repeat" : "no-repeat",
              backgroundColor: (t) =>
                t.palette.mode === "light"
                  ? t.palette.grey[50]
                  : t.palette.grey[900],
              backgroundSize: enableKiosk === "1" ? "cover" : "cover",
              backgroundPosition: enableKiosk === "1" ? "left" : "left",
              left: enableKiosk === "1" ? "0" : "0",
              right: enableKiosk === "1" ? "0" : "0",
              backgroundImage:
                enableKiosk === "0" ? `url(${siteMedSrc}signbg.png)` : null,
            }}
          />
          <Grid
            item
            xs={enableKiosk === "1" ? 10 : 12}
            sm={enableKiosk === "1" ? 10 : 8}
            md={enableKiosk === "1" ? 10 : 5}
            component={Paper}
            elevation={6}
            square
          >
            <Box
              id="target"
              sx={{
                my: 8,
                mx: 4,
                display: "flex",
                flexDirection: "column",
              }}
            >
              {enableNewUI == 1 ? (
                <img
                  src={`${siteMedSrc}logo.png`}
                  className="SL-logo02"
                  alt={`${restaurantName} logo`}
                />
              ) : enableNewUI == 0 ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={`${siteMedSrc}logo.png`}
                    width={logoWidth}
                    alt={`${restaurantName} logo`}
                    style={{
                      transform: "scale(1.75)",
                      paddingTop: ".5rem",
                      paddingBottom: "1rem",
                    }}
                  />
                </div>
              ) : (
                <img
                  src={`${siteMedSrc}logo.png`}
                  className="Default-logo"
                  alt={`${restaurantName} logo`}
                />
              )}
              <Typography
                className="content"
                sx={{ mt: 1, textAlign: "center", lineHeight: 1.1 }}
                component="h5"
                variant="h5"
              >
                <br />
                Hello. Please log into your account.
              </Typography>
              <Box
                className="content"
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{ mt: 1 }}
              >
                <TextField
                  fontSize={enableKiosk === "0" ? "normal" : "1.5rem"}
                  margin="normal"
                  required
                  fullWidth
                  id="firstname"
                  label="First Name"
                  name="First Name"
                  autoComplete="first-name"
                  autoFocus
                  variant="standard"
                  InputLabelProps={{
                    sx: {
                      fontSize: enableKiosk === "0" ? "1.25rem" : "1.5rem",
                    },
                  }}
                  InputProps={{
                    sx: {
                      fontSize: enableKiosk === "0" ? "1.25rem" : "1.5rem",
                    },
                  }}
                  sx={{ pt: 1.5 }}
                  onKeyUp={(e) => {
                    setFirstName(e.target.value);
                  }}
                />
                <TextField
                  fontSize={enableKiosk === "0" ? "normal" : "1.5rem"}
                  margin="normal"
                  required
                  fullWidth
                  name="room"
                  label={loginCustomerNumber == 1 ? "Badge #" : "Room #"}
                  type="room"
                  id="room"
                  autoComplete="room-number"
                  variant="standard"
                  InputLabelProps={{
                    sx: {
                      fontSize: enableKiosk === "0" ? "1.25rem" : "1.5rem",
                    },
                  }}
                  InputProps={{
                    sx: {
                      fontSize: enableKiosk === "0" ? "1.25rem" : "1.5rem",
                    },
                  }}
                  sx={{ pt: 1.5 }}
                  onKeyUp={(e) => {
                    setRoomNumber(e.target.value);
                  }}
                />
                <Button
                  fontSize={enableKiosk === "0" ? "normal" : "1.5rem"}
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 5,
                    mb: 2,
                    fontWeight: "600",
                    backgroundColor: buttonColor,
                    "&:hover": {
                      backgroundColor: hoverColor,
                    },
                  }}
                >
                  <Typography
                    className="content"
                    sx={{
                      fontSize: enableKiosk === "0" ? "1.25rem" : "1.75rem",
                      fontWeight: "600",
                    }}
                  >
                    LOGIN
                  </Typography>
                </Button>
              </Box>
            </Box>
            {enableNewUI == 0 ? <Copyright /> : null}
            <FontSizeChanger
              targets={["#target .content"]}
              onChange={(element, newValue, oldValue) => {
                console.log(element, newValue, oldValue);
              }}
              options={{
                stepSize: 2,
                range: 5,
              }}
              customButtons={{
                up: (
                  <AppBar
                    position="fixed"
                    sx={{
                      width: "90px",
                      boxShadow: 0,
                      top: "auto",
                      bottom: 237.5,
                      backgroundColor: "transparent",
                    }}
                  >
                    <Toolbar>
                      <Box sx={{ flexGrow: 1 }} />
                      <IconButton aria-label="add font size">
                        <Fab
                          color="primary"
                          aria-hidden="true"
                          sx={{ backgroundColor: "#3D5309" }}
                        >
                          <AddCircleOutlineIcon />
                        </Fab>
                      </IconButton>
                    </Toolbar>
                  </AppBar>
                ),
                down: (
                  <AppBar
                    position="fixed"
                    sx={{
                      width: "90px",
                      boxShadow: 0,
                      top: "auto",
                      bottom: 180,
                      backgroundColor: "transparent",
                    }}
                  >
                    <Toolbar>
                      <Box sx={{ flexGrow: 1 }} />
                      <IconButton>
                        <Fab
                          color="primary"
                          aria-hidden="true"
                          sx={{ backgroundColor: "#765F00" }}
                        >
                          <RemoveCircleOutlineIcon />
                        </Fab>
                      </IconButton>
                    </Toolbar>
                  </AppBar>
                ),
              }}
            />
          </Grid>
        </Grid>
        {enableNewUI === "1" ? (
          <>
            <BarTop />
            <BarBottom />
          </>
        ) : null}
      </ThemeProvider>
    </>
  );
};
